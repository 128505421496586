import React from "react";
import { Row, Input, Button, Table, Icon, Skeleton, Spin } from "antd";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/dateUtils";

class AlarmsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "Cargando...",
      statusCode: 0,
      dataSource: [],
      count: 0,
      searchText: "",
      searchedColumn: "",
      editingKey: "",
      dataToShow: [],
      currentPage: 1,
      status: "loading",
      size: 10,
      paginatedSearch: false,
      mustShowTable: false,
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 190, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reiniciar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  }

  handleReset(clearFilters) {
    clearFilters();
    this.setState({
      searchText: "",
    });
  }

  renderAlarms(record) {
    let alarm;

    if (record.alarmTemp) alarm = "Temperatura";
    if (record.alarmHum) alarm = "Humedad";
    if (record.alarmImpact) alarm = "Impacto";
    if (record.alarmOpen) alarm = "Apertura";

    return alarm;
  }

  handleSearchAlarms(searchParameters) {
    const {
      startDateAlarm,
      endDateAlarm,
      tempAlarm,
      impactAlarm,
      openAlarm,
      humAlarm,
      batDataloggerAlarm,
      batRemoteAlarm,
      onDateRangePicked,
      onParameterPicked,
    } = searchParameters;

    if (onDateRangePicked || onParameterPicked) {
      const defaultCurrentPage = 1;

      this.props.fetchAlarms(
        startDateAlarm,
        endDateAlarm,
        tempAlarm,
        impactAlarm,
        openAlarm,
        humAlarm,
        batDataloggerAlarm,
        batRemoteAlarm
      );

      this.props.setSearchParameters((prevState) => ({
        ...prevState,
        onDateRangePicked: false,
        onParameterPicked: false,
      }));
      this.setState({ currentPage: defaultCurrentPage });
    }
  }

  renderShipmentCode(shipmentCode) {
    if (shipmentCode !== "N/D") {
      return (
        <Link to={"/envio?shipmentCode=" + shipmentCode}>{shipmentCode}</Link>
      );
    }
    return shipmentCode;
  }

  componentDidMount() {
    if (
      this.props.searchParameters.onDateRangePicked ||
      this.props.searchParameters.onParameterPicked
    ) {
      this.handleSearchAlarms(this.props.searchParameters, this.state.size);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.searchParameters.triggerSearch) {
      this.handleSearchAlarms(
        this.props.searchParameters,
        this.state.size,
        this.state.paginatedSearch
      );

      this.props.setSearchParameters((prevState) => ({
        ...prevState,
        triggerSearch: false,
      }));
    }

    if (prevProps.alarmsData !== this.props.alarmsData) {
      if (
        this.props.alarmsData.statusCode === 0 &&
        this.props.alarmsData.status === "paginating"
      ) {
        this.setState({
          mustShowTable: true,
          status: "paginating",
        });
        return false;
      }

      if (this.props.alarmsData.statusCode !== 200) {
        this.setState({
          message: this.props.alarmsData.message,
          statusCode: this.props.alarmsData.statusCode,
          status: this.props.alarmsData.status,
          mustShowTable: false,
        });
        return false;
      }

      if (this.props.alarmsData.statusCode === 200) {
        if (
          !this.props.alarmsData.data.length &&
          this.props.alarmsData.status === "loaded"
        ) {
          this.setState({
            statusCode: 204,
            status: "loaded",
            message:
              "No se han encontrado resultados para los criterios de búsqueda seleccionados.",
            mustShowTable: false,
          });
          return false;
        }

        const processedData = this.props.alarmsData.data.map((record) => ({
          ...record,
          alarmName: this.renderAlarms(record),
        }));

        this.setState({
          dataSource: processedData,
          count: this.props.alarmsData.totalResults,
          dataToShow: processedData,
          status: this.props.alarmsData.status,
          statusCode: this.props.alarmsData.statusCode,
          currentPage: this.props.alarmsData.page,
          mustShowTable: true,
        });
      }
    }
  }

  render() {
    const { dataToShow, count, status, statusCode, message, mustShowTable } =
      this.state;

    const tableColumns = [
      {
        title: "Código de bulto",
        dataIndex: "shipmentCode",
        sorter: (a, b) => a.shipmentCode.localeCompare(b.shipmentCode),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 90,
        align: "center",
        ...this.getColumnSearchProps("shipmentCode"),
        render: (shipmentCode) => (
          <span>{this.renderShipmentCode(shipmentCode)}</span>
        ),
      },
      {
        title: "IMEI",
        dataIndex: "imei",
        sorter: (a, b) => a.imei.localeCompare(b.imei),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 85,
        align: "center",
        ...this.getColumnSearchProps("imei"),
      },
      {
        title: "Ref. cliente / pedido",
        dataIndex: "numRefClient",
        sorter: (a, b) => a.numRefClient.localeCompare(b.numRefClient),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 70,
        align: "center",
        ...this.getColumnSearchProps("numRefClient"),
      },
      {
        title: "Remitente",
        dataIndex: "senderName",
        sorter: (a, b) => a.senderName.localeCompare(b.senderName),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 90,
        align: "center",
        ...this.getColumnSearchProps("senderName"),
      },
      {
        title: "Último evento",
        dataIndex: "eventName",
        sorter: (a, b) => a.eventName.localeCompare(b.eventName),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 80,
        align: "center",
        render: (eventName, row) => (
          <div
            style={{
              border: "1px solid #91D5FF",
              borderRadius: "4px",
              transform: "scale(0.8)",
              color: "#1990FF",
              background: "#E6F7FF",
              padding: ".2rem",
              minWidth: "100%",
              lineHeight: "1.3",
            }}
          >
            {!eventName ? "N/D" : eventName}
          </div>
        ),
      },
      {
        title: "Fecha y hora evento",
        dataIndex: "eventDate",
        sorter: (a, b) => a.eventDate.localeCompare(b.eventDate),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 70,
        align: "center",
        render: (_, record) => {
          return <span>{formatDate(record.eventDate)}</span>;
        },
      },
      {
        title: "Ubicación del evento",
        dataIndex: "eventLocation",
        sorter: (a, b) => a.eventLocation.localeCompare(b.eventLocation),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 70,
        align: "center",
      },
      {
        title: "Destinatario",
        dataIndex: "destinationName",
        sorter: (a, b) => a.destinationName.localeCompare(b.destinationName),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 90,
        align: "center",
        ...this.getColumnSearchProps("destinationName"),
      },
      {
        title: "Alarma",
        dataIndex: "alarmName",
        sorter: (a, b) => a.alarmName.localeCompare(b.alarmName),
        editable: false,
        width: 90,
        align: "center",
      },
      {
        title: "Fecha y hora alarma",
        dataIndex: "alarmStartDate",
        sorter: (a, b) => a.alarmStartDate.localeCompare(b.alarmStartDate),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 70,
        align: "center",
        render: (_, record) => {
          return <span>{formatDate(record.alarmStartDate)}</span>;
        },
      },
    ];

    const columns = tableColumns.map((col) => {
      return col;
    });

    return (
      <div className="Alarmas">
        <h2>Resultados</h2>

        {status === "loading" && (
          <>
            <Skeleton active />
          </>
        )}

        {statusCode !== 200 && status === "loaded" && (
          <>
            <p>{message}</p>
          </>
        )}

        {mustShowTable && (
          <Spin
            tip="Cargando..."
            spinning={status === "paginating"}
            size="large"
            style={{ transform: "scale(2)" }}
          >
            <Table
              scroll={{ x: 500 }}
              bordered
              dataSource={dataToShow}
              columns={columns}
              pagination={{
                total: count,
                showTotal: (total) => `${total} resultados`,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["10", "20", "40", "80"],
                style: {
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  border: "1px solid #808080",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "0 0 5px 5px",
                  padding: "15px",
                  marginTop: "-1px",
                },
              }}
            />
          </Spin>
        )}
      </div>
    );
  }
}

export default withTranslation()(AlarmsTable);
