import React, { useState, useEffect } from "react";
import { Row, Col, Card, Statistic, Skeleton } from "antd";
import { withTranslation } from "react-i18next";

const green = "#3f8600";
const red = "#ca0909";

const TotalAlarms = ({ alarmsData, totalAlarms }) => {
  const [alarmParameters, setAlarmParameters] = useState({
    totalAlarmsTemp: 0,
    totalAlarmsImpact: 0,
    totalAlarmsOpen: 0,
    totalAlarmsHum: 0,
    totalAlarmsBatDatalogger: 0,
    totalAlarmsBatRemote: 0,
    status: "loading",
    message: "",
    statusCode: 0,
    mustShowPanel: false,
  });

  useEffect(() => {
    const { statusCode, status, message, data } = alarmsData;
    setAlarmParameters((prevState) => {
      if (statusCode === 0) {
        return {
          ...prevState,
          status,
          message: "Cargando...",
          statusCode,
          mustShowPanel: status === "paginating",
        };
      }
      if (statusCode !== 200) {
        return {
          ...prevState,
          status: "loaded",
          message,
          statusCode,
          mustShowPanel: false,
        };
      }
      if (statusCode === 200 && !data.length) {
        return {
          ...prevState,
          status: "loaded",
          message:
            "No se han encontrado alarmas para los criterios de búsqueda seleccionados.",
          statusCode: 204,
          mustShowPanel: false,
        };
      }
      return {
        ...prevState,
        status: "loaded",
        message,
        statusCode,
        mustShowPanel: true,
      };
    });
  }, [alarmsData]);

  useEffect(() => {
    setAlarmParameters((prevState) => ({
      ...prevState,
      ...totalAlarms,
    }));
  }, [totalAlarms]);

  const checkAlarmColor = (value) => (value > 0 ? red : green);
  const checkAlarmValue = (value) => (value > 0 ? "Sí" : "No");

  const alertCard = (title, value) => (
    <Col span={6}>
      <Card>
        <Statistic
          title={title}
          value={checkAlarmValue(value)}
          precision={0}
          valueStyle={{ color: checkAlarmColor(value) }}
        />
      </Card>
    </Col>
  );

  return (
    <div className="TotalAlarms">
      <h2>Alarmas</h2>
      {alarmParameters.status === "loading" && <Skeleton active />}
      {alarmParameters.status === "loaded" &&
        alarmParameters.statusCode !== 200 && <p>{alarmParameters.message}</p>}
      {alarmParameters.mustShowPanel && (
        <Row gutter={8}>
          {alertCard("Temperatura", alarmParameters.totalAlarmsTemp)}
          {alertCard("Impacto", alarmParameters.totalAlarmsImpact)}
          {alertCard("Humedad", alarmParameters.totalAlarmsHum)}
          {alertCard("Apertura", alarmParameters.totalAlarmsOpen)}
        </Row>
      )}
    </div>
  );
};

export default withTranslation()(TotalAlarms);
