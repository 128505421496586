import instance from "../Api/Instance";
import { back } from "../Api/ApiUrl";
import moment from "moment";
import { Modal } from "antd";
import { useState } from "react";
import { formatDate } from "../utils/dateUtils";

export default function useShipments() {
  const [shipmentData, setShipmentData] = useState({
    status: "loading",
    data: [],
    statusCode: 0,
    message: "Cargando...",
    size: 10,
    page: 1,
    totalResults: 0,
    totalPages: 0,
  });

  const [singleShipmentData, setSingleShipmentData] = useState({
    file: null,
    standAlone: false,
    statusCode: 0,
    message: "Cargando...",
    status: "loading",
    telemetryTypeCode: null,
    imei: null,
    macData: null,
  });

  const [telemetryInfo, setTelemetryInfo] = useState({
    warningsImpact: 0,
    warningsOpen: 0,
    warningsTemp: 0,
    warningsHum: 0,
    statusCode: 0,
  });

  const [alertsListInfo, setAlertsListInfo] = useState({
    tempMax: 0,
    tempMin: 0,
    alertsList: [],
    statusCode: 0,
  });

  const [chartsInfo, setChartsInfo] = useState({
    telemetryInfo: [],
    events: [],
    periodMinuteGuard: 0,
    tempMax: 0,
    tempMin: 0,
    statusCode: 0,
  });

  const [eventsInfo, setEventsInfo] = useState({
    steps: [],
    current: 0,
    statusCode: 0,
    message: "",
  });

  const generatePod = (shipmentCode, pod) => {
    const linkSource = `data:image/jpeg;base64,${pod}`;
    const downloadLink = document.createElement("a");
    const fileName = `${shipmentCode}.jpg`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const fetchShipments = (
    shipmentCode,
    numRefClient,
    start,
    end,
    size,
    page,
    pagination
  ) => {
    setShipmentData((prevState) => ({
      ...prevState,
      status: pagination ? "paginating" : "loading",
      message: "Cargando...",
      statusCode: 0,
    }));

    instance
      .get(back.getListEnvios, {
        params: {
          shipmentCode: shipmentCode,
          numRefClient: numRefClient,
          startDate: start ? moment(start).format("DD-MM-YYYY") : null,
          endDate: end ? moment(end).format("DD-MM-YYYY") : null,
          size: size || 10,
          page: page - 1 || 0,
        },
      })
      .then(function (response) {
        let processedShipments = [];

        if (response.status !== 200) {
          setShipmentData({
            status: "loaded",
            data: processedShipments,
            message: "No se han encontrado datos.",
            statusCode: response.status,
          });
        }

        if (response.status === 200) {
          if (response.data.shipmentList?.data) {
            response.data.shipmentList.data.forEach(function (shipment, index) {
              shipment["key"] = index;
              processedShipments.push(shipment);
            });
          }

          setShipmentData({
            status: "loaded",
            data: processedShipments,
            statusCode: response.status,
            message: "",
            size: response.data.shipmentList.pageSize,
            page: response.data.shipmentList.pageNumber + 1,
            totalResults: response.data.shipmentList.totalResults,
            totalPages: response.data.shipmentList.totalPages,
          });

          setTelemetryInfo({
            warningsImpact: response.data.alertTotal.warningsImpactTotal,
            warningsOpen: response.data.alertTotal.warningsOpenTotal,
            warningsTemp: response.data.alertTotal.warningsTempTotal,
            warningsHum: response.data.alertTotal.warningsHumTotal,
            statusCode: 200,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        setShipmentData((prevState) => ({
          ...prevState,
          status: "loaded",
          statusCode: 500,
          message: "Se ha producido un error, inténtelo de nuevo.",
        }));
      });
  };

  const fetchSingleShipment = async (shipmentCode) => {
    const url = back.getShipmentInfo;

    await instance
      .get(url, {
        params: {
          shipmentCode: shipmentCode,
        },
      })
      .then((response) => {
        const { data } = response;
        if (response.status !== 200) {
          setSingleShipmentData((prevState) => ({
            ...prevState,
            statusCode: response.status,
            status: "loaded",
            message: "No se han encontrado datos de envío.",
          }));

          setEventsInfo((prevState) => ({
            ...prevState,
            statusCode: response.status,
          }));
        }

        if (response.status === 200) {
          setSingleShipmentData({
            file: data.file,
            standAlone: data.standalone,
            statusCode: response.status,
            status: "loaded",
            telemetryTypeCode: data.telemetryTypeCode,
            imei: data.imei,
            macData: data.macData,
          });

          if (data.standalone) {
            if (!data.file) {
              if (data.alertTotal) {
                setTelemetryInfo((prevState) => ({
                  ...prevState,
                  warningsImpact: data.alertTotal.warningsImpactTotal,
                  warningsOpen: data.alertTotal.warningsOpenTotal,
                  warningsTemp: data.alertTotal.warningsTempTotal,
                  warningsHum: data.alertTotal.warningsHumTotal,
                  statusCode: response.status,
                }));
              }

              if (!data.alertTotal) {
                setTelemetryInfo((prevState) => ({
                  ...prevState,
                  warningsImpact: 0,
                  warningsOpen: 0,
                  warningsTemp: 0,
                  warningsHum: 0,
                  statusCode: response.status,
                }));
              }

              const singleShipmentTelemetryData =
                processSingleShipmentTelemetryData(data);
              setAlertsListInfo((prevState) => ({
                ...prevState,
                ...singleShipmentTelemetryData,
                statusCode: response.status,
              }));

              const singleShipmentChartsData =
                processSingleShipmentChartsData(data);
              setChartsInfo((prevState) => ({
                ...prevState,
                ...singleShipmentChartsData,
                statusCode: response.status,
              }));

              const singleShipmentEventsData =
                processSingleShipmentEvents(data);

              setEventsInfo((prevState) => ({
                ...prevState,
                ...singleShipmentEventsData,
                statusCode: 200,
              }));
            }
            const singleShipmentEventsData = processSingleShipmentEvents(data);

            setEventsInfo((prevState) => ({
              ...prevState,
              ...singleShipmentEventsData,
              statusCode: 200,
            }));
          }

          if (!data.standalone) {
            if (data.alertTotal) {
              setTelemetryInfo((prevState) => ({
                ...prevState,
                warningsImpact: data.alertTotal.warningsImpactTotal,
                warningsOpen: data.alertTotal.warningsOpenTotal,
                warningsTemp: data.alertTotal.warningsTempTotal,
                warningsHum: data.alertTotal.warningsHumTotal,
                statusCode: response.status,
              }));
            }

            if (!data.alertTotal) {
              setTelemetryInfo((prevState) => ({
                ...prevState,
                warningsImpact: 0,
                warningsOpen: 0,
                warningsTemp: 0,
                warningsHum: 0,
                statusCode: response.status,
              }));
            }

            const singleShipmentTelemetryData =
              processSingleShipmentTelemetryData(data);
            setAlertsListInfo((prevState) => ({
              ...prevState,
              ...singleShipmentTelemetryData,
              statusCode: response.status,
            }));

            const singleShipmentChartsData =
              processSingleShipmentChartsData(data);
            setChartsInfo((prevState) => ({
              ...prevState,
              ...singleShipmentChartsData,
              statusCode: response.status,
            }));

            const singleShipmentEventsData = processSingleShipmentEvents(data);

            setEventsInfo((prevState) => ({
              ...prevState,
              ...singleShipmentEventsData,
              statusCode: 200,
            }));
          }
        }
      })
      .catch((error) => {
        setSingleShipmentData((prevState) => ({
          ...prevState,
          statusCode: 500,
          message: "Se ha producido un error, inténtelo de nuevo.",
        }));

        setEventsInfo((prevState) => ({
          ...prevState,
          statusCode: 500,
          message: "",
        }));

        setTelemetryInfo((prevState) => ({
          ...prevState,
          statusCode: 500,
          message: "No se han encontrado datos de alertas para este envío",
        }));
        console.log(error);
      });
  };

  const processSingleShipmentTelemetryData = (data) => {
    const tempMin = data.tempMin || -50;
    const tempMax = data.tempMax || 50;
    const tempRisk = (tempMax - tempMin) * 0.1;

    const calcMin = tempMin > 50 ? -(tempMin - 50) : tempMin;
    const calcMax = tempMax > 50 ? -(tempMax - 50) : tempMax;

    const startDate = getDateFromEvent(
      data.shipmentTelemetryList?.[0]?.sampleDate
    );
    const minutesToAdd = parseInt(data.periodMinuteGuard, 10);
    const startDateWithGuard = startDate?.setMinutes(
      startDate.getMinutes() + minutesToAdd
    );

    // const endDate = getDateFromEvent(
    //   data.allEvents?.delivered?.eventDate ||
    //     data.allEvents?.returnedToSender?.eventDate
    // );

    const alertsList = data.shipmentTelemetryList
      ? generateAlerts(
          data.shipmentTelemetryList,
          calcMin,
          calcMax,
          tempRisk,
          startDateWithGuard
          // endDate
        )
      : [];

    return {
      tempMax: calcMax,
      tempMin: calcMin,
      alertsList,
      configTempMax: data.tempMax,
      configTempMin: data.tempMin,
    };
  };

  const getDateFromEvent = (eventDate) =>
    eventDate ? new Date(eventDate) : null;

  const generateAlerts = (
    telemetryList,
    calcMin,
    calcMax,
    tempRisk,
    startDate
    // endDate
  ) => {
    let lastAlertType = null;
    return telemetryList.reduce((alerts, v) => {
      const sampleDate = new Date(v.sampleDate);
      const isWithinDateRange = !startDate || sampleDate >= startDate;
      // && (!endDate || sampleDate <= endDate);

      if (isWithinDateRange) {
        const currentAlertType = checkAlertType(
          v.temperature,
          calcMin,
          calcMax,
          tempRisk
        );

        if (currentAlertType && currentAlertType !== lastAlertType) {
          alerts.push(createAlert(currentAlertType, v.sampleDate));
        }
        lastAlertType = currentAlertType;
      }

      return alerts;
    }, []);
  };

  const checkAlertType = (temperature, calcMin, calcMax, tempRisk) => {
    if (temperature < calcMin || temperature > calcMax) {
      return "alert";
    } else if (
      temperature >= calcMax - tempRisk ||
      temperature <= calcMin + tempRisk
    ) {
      return "warning";
    }
    return null;
  };

  const createAlert = (alertType, date) => ({
    class: alertType,
    type: "temperatura",
    message:
      alertType === "alert"
        ? "El contenedor está fuera de los rangos de temperatura establecidos."
        : "La temperatura está cerca de salirse de los rangos establecidos.",
    date: formatDate(date),
  });

  const processSingleShipmentChartsData = (data) => {
    let calcMin = data.tempMin;
    let calcMax = data.tempMax;
    let chartData = [];
    let chartEvents = [];
    let periodMinuteGuard;

    if (data.tempMin > 50) {
      calcMin = -(data.tempMin - 50);
    }

    if (data.tempMax > 50) {
      calcMax = -(data.tempMax - 50);
    }

    if (data.shipmentTelemetryList) {
      data.shipmentTelemetryList.forEach((datum) => {
        let temp = datum.temperature;
        let humidity = datum.humidity;

        if (datum.humidity > 100) {
          temp = -datum.temperature;
          humidity = datum.humidity - 100;
        }

        chartData.push({
          date: formatDate(datum.sampleDate),
          temp: temp,
          humidity: humidity,
        });
      });
    }

    if (data.periodMinuteGuard) {
      periodMinuteGuard = data.periodMinuteGuard;
    }

    if (data.allEvents) {
      const { preRegistered, admitted, delivered, inReturn, returnedToSender } =
        data.allEvents;

      const eventsSingleShipment = [
        { event: preRegistered, name: "Pre-registro", ref: "preRegistered" },
        { event: admitted, name: "Admitido", ref: "admitted" },
        { event: delivered, name: "Entregado", ref: "delivered" },
        { event: inReturn, name: "En devolución", ref: "inReturn" },
        {
          event: returnedToSender,
          name: "Entregado al remitente",
          ref: "returnedToSender",
        },
      ];

      eventsSingleShipment.forEach(({ event, name, ref }) => {
        if (event) {
          chartEvents.push({ name, date: formatDate(event.eventDate), ref });
        }
      });
    }

    return {
      telemetryInfo: chartData,
      events: chartEvents,
      tempMax: calcMax,
      tempMin: calcMin,
      periodMinuteGuard: periodMinuteGuard,
    };
  };

  const updateStep = (steps, stepName, event, index) => {
    if (event) {
      steps[index] = {
        title: stepName,
        description: formatDate(event.eventDate),
        location: event.eventLocation,
      };
      return index;
    }
    return null;
  };

  const handleReturnSteps = (steps, inReturn, returnedToSender) => {
    let current = 0;
    if (inReturn) {
      steps[3] = {
        title: "En devolución",
        description: formatDate(inReturn.eventDate),
        location: inReturn.eventLocation,
      };
      steps.push({ title: "Entregado al remitente" });
      current = 3;
    }

    if (returnedToSender) {
      steps[4] = {
        title: "Entregado al remitente",
        description: formatDate(returnedToSender.eventDate),
        location: returnedToSender.eventLocation,
      };
      current = 4;
    }

    return current;
  };

  const processSingleShipmentEvents = (data) => {
    const defaultSteps = [
      { title: "Pre-registro" },
      { title: "En camino" },
      { title: "En reparto" },
      { title: "Entregado" },
    ];
    let current = 0;

    if (data.allEvents) {
      const {
        preRegistered,
        inTransit,
        outForDelivery,
        delivered,
        inReturn,
        returnedToSender,
      } = data.allEvents;

      const events = [
        { event: preRegistered, stepName: "Pre-registro", index: 0 },
        { event: inTransit, stepName: "En camino", index: 1 },
        { event: outForDelivery, stepName: "En reparto", index: 2 },
        { event: delivered, stepName: "Entregado", index: 3 },
      ];

      events.forEach(({ event, stepName, index }) => {
        current = updateStep(defaultSteps, stepName, event, index) ?? current;
      });

      current =
        handleReturnSteps(defaultSteps, inReturn, returnedToSender) || current;
    }

    return {
      steps: defaultSteps,
      current,
    };
  };

  const fetchShipmentPod = (shipmentCode) => {
    instance
      .get(back.fetchShipmentPod, {
        params: {
          shipmentCode: shipmentCode,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          return Modal.error({
            title: "No disponible",
            content:
              "La imagen de firma (POD) no está disponible para su descarga.",
          });
        }
        if (response.status === 200) {
          const { shipmentCode, pod } = response.data;
          return Modal.success({
            title: `POD ${shipmentCode}`,
            content: "La imagen de firma se ha generado con éxito.",
            onOk: () => generatePod(shipmentCode, pod),
            okText: "Descargar",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        return Modal.error({
          title: "No disponible",
          content:
            "La imagen de firma (POD) no está disponible para su descarga.",
        });
      });
  };

  return {
    shipmentData,
    singleShipmentData,
    fetchShipments,
    fetchSingleShipment,
    telemetryInfo,
    alertsListInfo,
    chartsInfo,
    eventsInfo,
    fetchShipmentPod,
  };
}
