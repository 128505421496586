export function parseDate(dateString) {
  if (!dateString) {
    console.warn("Fecha inválida o nula pasada a parseDate:", dateString);
    return null;
  }
  const [day, month, yearAndTime] = dateString.split("/");
  const [year, time] = yearAndTime.split(" ");
  return new Date(`${month}/${day}/${year} ${time}`);
}

export function formatDate(date) {
  let formattedDate = new Date(date);

  if (formattedDate == "Invalid Date") {
    return "N/D";
  }

  return formattedDate
    .toLocaleDateString("es-Es", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
    .replace(",", "");
}

export function formatAlarmsDate(dateString) {
  const date = new Date(dateString);

  const pad = (num) => String(num).padStart(2, "0");

  const year = date.getUTCFullYear();
  const month = pad(date.getUTCMonth() + 1);
  const day = pad(date.getUTCDate());
  const hours = pad(date.getUTCHours());
  const minutes = pad(date.getUTCMinutes());

  return `${year}-${month}-${day}T${hours}:${minutes}:00.000+00:00`;
}
