import React from "react";
import "./Ayuda.scss";
import pdf from "../../../public/documents/Manual_PCF_Clientes_v.9.0.pdf";

export default function Ayuda() {
  return (
    <>
      <div className="Ayuda">
        <object
          style={{ width: "100%", height: "600pt" }}
          type="application/pdf"
          data={pdf}
        ></object>
      </div>
    </>
  );
}
