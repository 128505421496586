/* eslint-disable */
import React, { Component, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Row, Col, Layout, ConfigProvider } from "antd";
import Spanish from "antd/lib/locale/es_ES";
import { MLateral, BtnBurguer, MyRoutes } from "correos-arq-mlateral";
import { MHorizontal } from "correos-arq-mhorizontal";
import { BreadCrumb } from "correos-arq-breadcrumb";
import { Login, PrivateRoute, RedirectLogin } from "correos-arq-auth";
import { storeArq } from "correos-arq-utils";
import i18n from "./i18nConfig/i18n";
import pathRoutes from "./router/routes";
import authRoutes from "./router/authroutes";
import Header from "./components/Header/Header";
import rolesWeb from "./router/rolesweb";
import rolesWebCId from "./router/roleswebcid";
import { CookieContext, cookies } from "./context/CookieContext";
import CookieBanner from "./components/CookieBanner/CookieBanner";
import UserFooter from "./components/UserFooter/UserFooter";
// Declararación del Content del Layout de antd
const { Content } = Layout;
const styles = {
  HomeStyle: {
    margin: "0.357em",
  },
};

storeArq.load();

class App extends Component {
  constructor(props) {
    super(props);
    require("dotenv").config();
    this.state = {
      showMenu: true, //Inicializa con el menu lateral abierto
      auth: storeArq.data.auth,
      areWeHome: true, //Indica si se esta en "/" para renderizar un estilo u otro
      reload: false, //Recargar app para actualizar submenus
      setInfo: false, //Indica cuando se pulsa el boton hamburguesa
      banner: cookies.showBanner,
    };
  }
  acceptCookies = () => {
    this.setState({ banner: !this.state.banner });
  };
  setInfoBtn = (info) => {
    const { showMenu } = this.state;
    showMenu && this.setState({ setInfo: info, showMenu: false });
    !showMenu && this.setState({ setInfo: info, showMenu: true });
  };

  UNSAFE_componentWillMount = () => {
    RedirectLogin();
  };

  // descomentar UNSAFE_component para develop

  clickHome = (key) => {
    this.setState({ areWeHome: key });
  };
  /* *
   * Muestra el menú o lo oculta
   * según el estado rederizado al hacer click al botón
   * */
  clickMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  clickMenuH = () => {
    const { showMenu } = this.state;
    !showMenu && this.setState({ showMenu: true });
  };

  /* *
   * Se usa en migasDePan para lanzar el render de la aplicacion por cambio de url
   * cambiando los submenus
   * */
  reloadApp = () => {
    this.setState({ reload: !this.state.reload });
  };

  componentDidMount() {
    const infoUser = JSON.parse(sessionStorage.getItem("storeArq.data"));
    const id = infoUser?.auth?.userInfo?.identificador;

    if (id) {
      //id del session storage
      const id64 = btoa(id);

      //id del local storage
      const cookie = JSON.parse(localStorage.getItem(id64));

      //si existe el id del sesion storage en local storage cookieaccepted = true
      if (cookie) {
        //setear el cokieaccepted a true
        this.acceptCookies();
      }
    }
  }
  render() {
    const { t } = this.props;
    const { showMenu } = this.state;
    storeArq.load();
    let subMenu = this.state.auth.userInfo.subMenu || {
      childs: [],
      parent: "",
    };

    let menuLateral = "";
    let migasDePan = "";
    let menuPPal = "";
    let menuLatBoton = null;
    let filteredPathRoutes = pathRoutes.filter(route => route.label !== "Envío");
    let newPathRoutes = MyRoutes.getNewPathRoutes(pathRoutes);

    subMenu = MyRoutes.getSubMenu(pathRoutes, subMenu);

    //Comprobar si hay submenus para renderizar el boton de menu lateral
    subMenu.childs.length > 0
      ? (menuLatBoton = (
          <BtnBurguer showMenu={showMenu} setInfoBtn={this.setInfoBtn} />
        ))
      : (menuLatBoton = null);

    if (this.state.auth.token === "" || window.location.pathname === "/login") {
      menuPPal = <div className="MHorizontal" />;
    } else {
      menuPPal = (
        <MHorizontal
          items={filteredPathRoutes}
          pathRoutes={filteredPathRoutes}
          clickMenu={this.clickMenuH}
          areWeHome={this.state.areWeHome}
          clickHome={this.clickHome}
        />
      );

      if (showMenu) {
        menuLateral = (
          <Row>
            <Col lg={4} style={{ width: "auto" }}>
              <MLateral
                longItem="20"
                items={subMenu}
                reloadApp={this.reloadApp}
              />
            </Col>
          </Row>
        );
        //Necesario pasar pathname como props aunque no se utilice
        //Sirve para renderizar otra vez las migas cuando se cambia la url
        migasDePan = (
          <Row>
            <Col span={1} className="ContainBtn">
              {menuLatBoton}
            </Col>
            {/* <BreadCrumb
              clickHome={this.clickHome}
              path={window.location.pathname}
              clickMenu={showMenu && this.clickMenu}
              routes={newPathRoutes.map((item) => ({
                path: item.path,
                label: item.label,
              }))}
              reloadApp={this.reloadApp}
            /> */}
          </Row>
        );
      } else {
        menuLateral = (
          <Row>
            <Col span={1}>{menuLatBoton}</Col>
            {/* <BreadCrumb
              clickHome={this.clickHome}
              path={window.location.pathname}
              clickMenu={showMenu && this.clickMenu}
              routes={newPathRoutes}
              reloadApp={this.reloadApp}
            /> */}
          </Row>
        );
      }
    }

    return (
      <ConfigProvider locale={Spanish}>
        <CookieContext.Provider value={this.state.banner}>
          <Router>
            <div className="App">
              <Layout className="layout">
                <Header
                  clickHome={this.clickHome}
                  clickMenu={showMenu && this.clickMenu}
                  i18n={i18n}
                  logoutSys={true}
                  pathHelp={"/ayuda"}
                />
                <Layout className="layout">{menuPPal}</Layout>

                <Layout className="layout">
                  <Row className="main-container">
                    <Col lg={24}>
                      <Layout className="layout">
                        {menuLateral}
                        <Layout className="layout">
                          {migasDePan}
                          <Content
                            className={
                              showMenu === true ? "ContentSpace" : "Contenido"
                            }
                            style={
                              window.location.pathname === "/" ||
                              window.location.pathname === "/salidadatos" ||
                              window.location.pathname === "/RutaPrivada" ||
                              window.location.pathname === "/ayuda" ||
                              window.location.pathname === "/envio" ||
                              window.location.pathname === "/alarmas"
                                ? styles.HomeStyle
                                : null
                            }
                          >
                            <CookieBanner />

                            <Switch>
                              {newPathRoutes.map(({ component, path }) => (
                                <PrivateRoute
                                  exact
                                  key={path}
                                  path={path}
                                  component={component}
                                  auth={this.state.auth}
                                />
                              ))}
                              {/* <Route
                                path="/login"
                                render={(props) => (
                                  <Login
                                    rolesWeb={rolesWeb}
                                    rolesWebCId={rolesWebCId}
                                    i18n={i18n}
                                    tipoIntegracion="isam"
                                    {...props}
                                  />
                                )}
                              /> */}
                              {authRoutes.map((route) => {
                                return (
                                  <Route
                                    path={route.path}
                                    component={
                                      route.type == "function"
                                        ? route.component
                                        : null
                                    }
                                  />
                                );
                              })}
                            </Switch>
                          </Content>
                        </Layout>
                      </Layout>
                    </Col>
                  </Row>
                  <UserFooter />
                </Layout>
              </Layout>
            </div>
          </Router>
        </CookieContext.Provider>
      </ConfigProvider>
    );
  }
}

export default withTranslation()(App);
