import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Row, Col, Card, Statistic, Skeleton, Spin } from "antd";

import { withTranslation } from "react-i18next";

let green = "#3f8600";
let red = "#ca0909";

const getWarningStatus = (warningValue) => (warningValue > 0 ? "Sí" : "No");

function Alerts({
  searchParameters,
  hasTelemetry,
  t,
  shipmentData,
  shipmentCode,
  telemetryInfo,
  isStandalone,
}) {
  let startValue, endValue;

  if (searchParameters) {
    startValue = searchParameters.startValue;
    endValue = searchParameters.endValue;
  }

  const [alertParameters, setAlertParameters] = useState({
    message: "Cargando...",
    warningsImpact: 0,
    warningsOpen: 0,
    warningsTemp: 0,
    warningsHum: 0,
    warningsImpactColor: "",
    warningsOpenColor: "",
    warningsTempColor: "",
    warningsHumColor: "",
    status: "loading",
    statusCode: 0,
    shipmentCode: shipmentCode,
    startValue: startValue,
    endValue: endValue,
    mustShowPanel: false,
  });

  useEffect(() => {
    if (!hasTelemetry) {
      if (shipmentData.statusCode === 0) {
        setAlertParameters((prevState) => ({
          ...prevState,
          message: "Cargando...",
          status: shipmentData.status,
          statusCode: 0,
          mustShowPanel: shipmentData.status === "paginating",
        }));
        return false;
      }

      if (shipmentData.statusCode !== 200) {
        setAlertParameters((prevState) => ({
          ...prevState,
          message: shipmentData.message,
          status: "loaded",
          statusCode: shipmentData.statusCode,
          mustShowPanel: false,
        }));
        return false;
      }

      if (shipmentData.statusCode === 200) {
        if (!shipmentData.data.length) {
          setAlertParameters((prevState) => ({
            ...prevState,
            statusCode: 204,
            status: "loaded",
            message: "No se han encontrado datos.",
            mustShowPanel: false,
          }));
          return false;
        }

        // comentamos esto porque los datos ahora vienen con telemetryInfo
        // desde el servidor
        // let shipmentWarnings = {
        //   warningsImpact: 0,
        //   warningsOpen: 0,
        //   warningsTemp: 0,
        // };

        // shipmentData.data.forEach((shipment) => {
        //   shipmentWarnings.warningsImpact += shipment.warningsImpact;
        //   shipmentWarnings.warningsOpen += shipment.warningsOpen;
        //   shipmentWarnings.warningsTemp += shipment.warningsTemp;
        // });

        setAlertParameters((prevState) => ({
          ...prevState,
          status: "loaded",
          message: shipmentData.message,
          statusCode: shipmentData.statusCode,
          mustShowPanel: true,
        }));
      }
    }
  }, [shipmentData]);

  useEffect(() => {
    setAlertParameters((prevState) => ({
      ...prevState,
      warningsImpact: telemetryInfo.warningsImpact,
      warningsOpen: telemetryInfo.warningsOpen,
      warningsTemp: telemetryInfo.warningsTemp,
      warningsHum: telemetryInfo.warningsHum,
      warningsImpactColor: telemetryInfo.warningsImpact === 0 ? green : red,
      warningsOpenColor: telemetryInfo.warningsOpen === 0 ? green : red,
      warningsTempColor: telemetryInfo.warningsTemp === 0 ? green : red,
      warningsHumColor: telemetryInfo.warningsHum === 0 ? green : red,
    }));
  }, [telemetryInfo]);

  useEffect(() => {
    // solo entra en efecto cuando accede a la página individual de pedido
    if (hasTelemetry) {
      if (telemetryInfo?.statusCode !== 200) {
        return setAlertParameters((prevState) => ({
          ...prevState,
          message: "No se han encontrado datos de alertas para este envío.",
          status: "loaded",
          statusCode: telemetryInfo?.statusCode,
        }));
      }
      if (telemetryInfo?.statusCode === 200) {
        setAlertParameters((prevState) => ({
          ...prevState,
          status: "loaded",
          statusCode: telemetryInfo.statusCode,
          warningsImpact: telemetryInfo.warningsImpact,
          warningsOpen: telemetryInfo.warningsOpen,
          warningsTemp: telemetryInfo.warningsTemp,
          warningsHum: telemetryInfo.warningsHum,
          shipmentCode: shipmentCode,
          warningsImpactColor: telemetryInfo.warningsImpact === 0 ? green : red,
          warningsOpenColor: telemetryInfo.warningsOpen === 0 ? green : red,
          warningsTempColor: telemetryInfo.warningsTemp === 0 ? green : red,
          warningsHumColor: telemetryInfo.warningsHum === 0 ? green : red,
          mustShowPanel: true,
        }));
      }
    }
  }, [telemetryInfo]);

  return (
    <div className="Alertas">
      <h2>{t("pages_Home.alerts")}</h2>

      {alertParameters.status === "loading" && (
        <>
          <Skeleton active />
        </>
      )}

      {alertParameters.statusCode !== 200 &&
        alertParameters.status === "loaded" && (
          <>
            <p>{alertParameters.message}</p>
          </>
        )}

      {alertParameters.mustShowPanel && (
        <div>
          <Row gutter={16}>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Temperatura"
                  value={getWarningStatus(alertParameters.warningsTemp)}
                  precision={0}
                  valueStyle={{ color: alertParameters.warningsTempColor }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Humedad"
                  value={
                    isStandalone
                      ? "N/D"
                      : getWarningStatus(alertParameters.warningsHum)
                  }
                  precision={0}
                  valueStyle={{
                    color: isStandalone
                      ? undefined
                      : alertParameters.warningsHumColor,
                  }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Impacto"
                  value={
                    isStandalone
                      ? "N/D"
                      : getWarningStatus(alertParameters.warningsImpact)
                  }
                  precision={0}
                  valueStyle={{
                    color: isStandalone
                      ? undefined
                      : alertParameters.warningsImpactColor,
                  }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Apertura"
                  value={
                    isStandalone
                      ? "N/D"
                      : getWarningStatus(alertParameters.warningsOpen)
                  }
                  precision={0}
                  valueStyle={{
                    color: isStandalone
                      ? undefined
                      : alertParameters.warningsOpenColor,
                  }}
                />
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default withTranslation()(Alerts);
