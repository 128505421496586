import instance from "../Api/Instance";
import { back } from "../Api/ApiUrl";
import { useState } from "react";
import { formatAlarmsDate } from "../utils/dateUtils";

export default function useAlarms() {
  const [alarmsData, setAlarmsData] = useState({
    status: "loading",
    data: [],
    statusCode: 0,
    message: "Cargando...",
    size: 10,
    page: 1,
    totalResults: 0,
    totalPages: 0,
  });

  const [totalAlarms, setTotalAlarms] = useState({
    totalAlarmsTemp: 0,
    totalAlarmsImpact: 0,
    totalAlarmsOpen: 0,
    totalAlarmsHum: 0,
  });

  const fetchAlarms = (
    startDateAlarm,
    endDateAlarm,
    tempAlarm,
    impactAlarm,
    openAlarm,
    humAlarm, 
  ) => {
    setAlarmsData((prevState) => ({
      ...prevState,
      status: "loading",
      message: "Cargando...",
      statusCode: 0,
    }));

    const formattedStartDate = startDateAlarm
      ? formatAlarmsDate(startDateAlarm)
      : null;
    const formattedEndDate = endDateAlarm
      ? formatAlarmsDate(endDateAlarm)
      : null;

    instance
      .get(back.getAlarms, {
        params: {
          startDateAlarm: formattedStartDate,
          endDateAlarm: formattedEndDate,
          tempAlarm: tempAlarm,
          impactAlarm: impactAlarm,
          openAlarm: openAlarm,
          humAlarm: humAlarm,
          batDataloggerAlarm: 0,
          batRemoteAlarm: 0,
        },
      })
      .then(function (response) {
        let processedAlarms = [];

        if (response.status !== 200) {
          setAlarmsData({
            status: "loaded",
            data: processedAlarms,
            message: "No se han encontrado datos.",
            statusCode: response.status,
          });
        }

        if (response.status === 200) {
          if (response.data.alarmList?.data) {
            response.data.alarmList.data.forEach(function (alarm, index) {
              alarm["key"] = index;
              processedAlarms.push(alarm);
            });

            setAlarmsData({
              status: "loaded",
              data: processedAlarms,
              message: "",
              statusCode: response.status,
            });

            setTotalAlarms({
              totalAlarmsTemp: response.data.totalAlarms.totalAlarmsTemp,
              totalAlarmsImpact: response.data.totalAlarms.totalAlarmsImpact,
              totalAlarmsOpen: response.data.totalAlarms.totalAlarmsOpen,
              totalAlarmsHum: response.data.totalAlarms.totalAlarmsHum,
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        setAlarmsData((prevState) => ({
          ...prevState,
          status: "loaded",
          statusCode: 500,
          message: "Se ha producido un error, inténtelo de nuevo.",
        }));
      });
  };

  return { alarmsData, totalAlarms, fetchAlarms };
}
