import React from "react";
import { withTranslation } from "react-i18next";
import Alerts from "../Alerts/Alerts";
import AlertsList from "../AlertsList/AlertsList";
import ShipmentCharts from "../ShipmentCharts/ShipmentCharts";

function StandAloneShipmentData({
  shipmentParameters,
  telemetryInfo,
  alertsListInfo,
  chartsInfo,
}) {
  if (
    !shipmentParameters.file &&
    chartsInfo.telemetryInfo.length === 0 &&
    shipmentParameters.telemetryTypeCode === null
  ) {
    return (
      <div style={{ height: "20vw", textAlign: "center", paddingTop: "8vw" }}>
        <h4>El pedido se ha registrado pero aún no se han cargado datos.</h4>
      </div>
    );
  }

  if (!shipmentParameters.file && shipmentParameters.telemetryTypeCode === 1) {
    return (
      <div style={{ height: "20vw", textAlign: "center", paddingTop: "8vw" }}>
        <h4>
          No se dispone de registro de temperatura del envío debido a que el
          datalogger retornó a nuestras instalaciones sin que se haya iniciado
          el proceso de grabación de datos en origen. En caso de cualquier duda
          o consulta relativa al proceso de inicio de grabación de datos
          consulta con tu gestor comercial.
        </h4>
      </div>
    );
  }

  if (!shipmentParameters.file && shipmentParameters.telemetryTypeCode === 2) {
    return (
      <div style={{ height: "20vw", textAlign: "center", paddingTop: "8vw" }}>
        <h4>
          No se dispone de registro de temperatura del envío debido a que la
          caja isotérmica retornó a nuestras instalaciones sin datalogger en el
          interior.
        </h4>
      </div>
    );
  }

  if (shipmentParameters.file && shipmentParameters.telemetryTypeCode === 3) {
    return (
      <div>
        <object
          style={{ width: "100%", height: "600pt" }}
          type="application/pdf"
          data={"data:application/pdf;base64," + shipmentParameters.file}
        ></object>
      </div>
    );
  }

  return (
    <div>
      <h2>Tipo de datalogger: StandAlone</h2>
      <h2>IMEI de caja: {shipmentParameters.imei}</h2>
      {!shipmentParameters.file && chartsInfo.telemetryInfo.length === 0 ? (
        <h4 style={{ height: "20vw", textAlign: "center", paddingTop: "8vw" }}>
          El envío no dispone por el momento de registros de telemetría. Por
          favor, inténtelo de nuevo más tarde.
        </h4>
      ) : (
        <div>
          <Alerts
            shipmentCode={shipmentParameters.shipmentCode}
            telemetryInfo={telemetryInfo}
            hasTelemetry
            isStandalone={shipmentParameters.standalone}
          />
          <AlertsList
            shipmentCode={shipmentParameters.shipmentCode}
            alertsListInfo={alertsListInfo}
          />
          <ShipmentCharts
            shipmentCode={shipmentParameters.shipmentCode}
            chartsInfo={chartsInfo}
            isStandalone={shipmentParameters.standalone}
          />
        </div>
      )}
    </div>
  );
}

export default withTranslation()(StandAloneShipmentData);
