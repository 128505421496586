import React from "react";
import { Row, Col, List, Collapse } from "antd";
import { withTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const { Panel } = Collapse;

function AlertsList({ shipmentCode, alertsListInfo }) {
  const [alertsListParameters, setAlertsListParameters] = useState({
    message: "Cargando...",
    status: "loading",
    statusCode: 0,
    shipmentCode: shipmentCode,
    alertsList: [],
    tempMin: 0,
    tempMax: 0,
  });

  useEffect(() => {
    if (alertsListInfo.statusCode !== 200) {
      setAlertsListParameters((prevState) => ({
        ...prevState,
        message: "No se han encontrado datos de telemetría para este envío.",
        status: "loaded",
        statusCode: alertsListInfo.statusCode,
      }));
    }

    if (alertsListInfo.statusCode === 200) {
      setAlertsListParameters((prevState) => ({
        ...prevState,
        status: "loaded",
        ...alertsListInfo,
      }));
    }
  }, [alertsListInfo]);

  const renderTemperatureRange = () => {
    let minTemp =
      alertsListParameters.configTempMin !== null
        ? `${alertsListParameters.tempMin}°C`
        : "-";
    let maxTemp =
      alertsListParameters.configTempMax !== null
        ? `${alertsListParameters.tempMax}°C`
        : "-";

    let message =
      alertsListParameters.configTempMax === null &&
      alertsListParameters.configTempMin === null
        ? "No aplica rango de temperatura"
        : `Rango de temperatura configurada: ${minTemp} a ${maxTemp}`;

    return <h4>{message}</h4>;
  };

  const renderIconLegend = () => (
    <div className="icon-legend">
      <div className="icon-circle alert-icon">T</div>
      <span className="icon-description"> = Alerta de Temperatura</span>
      <div className="icon-circle warning-icon">T</div>
      <span className="icon-description"> = Pre-alerta de Temperatura</span>
    </div>
  );

  const renderAlertsList = () => {
    return (
      <List
        itemLayout="horizontal"
        dataSource={alertsListParameters.alertsList}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <div
                  className={`icon-circle ${
                    item.class === "warning" ? "warning-icon" : "alert-icon"
                  }`}
                >
                  T
                </div>
              }
              title={item.date}
              description={item.message}
            />
          </List.Item>
        )}
      />
    );
  };

  return (
    <div>
      {alertsListParameters.status === "loading" ||
      alertsListParameters.statusCode !== 200 ? (
        <>
          <p>{alertsListParameters.message}</p>
        </>
      ) : (
        <Row gutter={16}>
          <Col span={24}>{renderTemperatureRange()}</Col>
        </Row>
      )}

      <div className="listAlertas">
        <Collapse
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => <span>{isActive ? "▼" : "▶"}</span>}
        >
          <Panel header="Listado de alertas" key="0" className="Busca">
            {renderIconLegend()}
            {renderAlertsList(alertsListParameters.alertsList)}
          </Panel>
        </Collapse>
      </div>
    </div>
  );
}

export default withTranslation()(AlertsList);
