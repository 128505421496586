import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import AlarmsSearch from "../../components/AlarmsSearch/AlarmsSearch";
import useAlarms from "../../hooks/useAlarms";
import TotalAlarms from "../../components/TotalAlarms/TotalAlarms";
import AlarmsTable from "../../components/AlarmsTable/AlarmsTable";

function Alarms() {
  const [searchParameters, setSearchParameters] = useState({
    startDateAlarm: null,
    endDateAlarm: null,
    tempAlarm: 0,
    impactAlarm: 0,
    openAlarm: 0,
    humAlarm: 0,
    onDateRangePicked: false,
    onParameterPicked: false,
    searchWasMade: false,
    triggerSearch: false,
  });

  const { fetchAlarms, alarmsData, totalAlarms } = useAlarms(null);

  const handleDateChange = (dates) => {
    setSearchParameters((prevState) => ({
      ...prevState,
      startDateAlarm: dates[0],
      endDateAlarm: dates[1],
      onDateRangePicked: !!dates[0] && !!dates[1],
      triggerSearch: false,
    }));
  };

  const handleExtraFilterChange = (labels) => {
    const newFilters = {
      tempAlarm: 0,
      impactAlarm: 0,
      openAlarm: 0,
      humAlarm: 0,
    };

    labels.forEach((label) => {
      const filter = filterOptions.find((option) => option.label === label);
      if (filter) {
        newFilters[filter.value] = 1;
      }
    });

    setSearchParameters((prevState) => ({
      ...prevState,
      ...newFilters,
      onParameterPicked: true,
      triggerSearch: false,
    }));
  };

  const handleSearch = () => {
    const {
      startDateAlarm,
      endDateAlarm,
      tempAlarm,
      impactAlarm,
      openAlarm,
      humAlarm,
    } = searchParameters;

    const alarmParams = {
      tempAlarm,
      impactAlarm,
      openAlarm,
      humAlarm,
    };

    const noAlarmsSelected = Object.values(alarmParams).every(
      (value) => value === 0
    );

    const updatedParams = noAlarmsSelected
      ? Object.fromEntries(Object.keys(alarmParams).map((key) => [key, 1]))
      : alarmParams;

    if (startDateAlarm && endDateAlarm) {
      setSearchParameters((prevState) => ({
        ...prevState,
        ...updatedParams,
        searchWasMade: true,
        onParameterPicked: true,
        onDateRangePicked: true,
        triggerSearch: true,
      }));
    }
  };

  const filterOptions = [
    { label: "Temperatura", value: "tempAlarm" },
    { label: "Impacto", value: "impactAlarm" },
    { label: "Humedad", value: "humAlarm" },
    { label: "Apertura", value: "openAlarm" },
  ];

  return (
    <div className="Alarms">
      <AlarmsSearch
        onSearch={handleSearch}
        onDateChange={handleDateChange}
        onExtraFilterChange={handleExtraFilterChange}
        filterOptions={filterOptions}
        searchParameters={searchParameters}
      />
      {searchParameters.searchWasMade && (
        <>
          <TotalAlarms alarmsData={alarmsData} totalAlarms={totalAlarms} />
          <AlarmsTable
            alarmsData={alarmsData}
            fetchAlarms={fetchAlarms}
            searchParameters={searchParameters}
            setSearchParameters={setSearchParameters}
          />
        </>
      )}
    </div>
  );
}

export default withTranslation()(Alarms);
