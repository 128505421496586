import React from "react";
import { ReferenceLine } from "recharts";
import { parseDate } from "./dateUtils";
import { YAxis } from "recharts";

// Procesamiento de la información del gráfico
export const processChartInfo = (chartsInfo) => {
  const telemetryDates = chartsInfo.telemetryInfo.map((data) => data.date);
  const adjustedEvents = chartsInfo.events.map((event) => ({
    ...event,
    closestDate: findNextDate(event.date, telemetryDates) || null,
  }));

  let eventTelemetry = [];
  if (telemetryDates.length > 0) {
    eventTelemetry = adjustedEvents
      .filter((event) => event.closestDate === null)
      .map((event) => ({
        date: event.date,
        temp: NaN,
        humidity: NaN,
        isEvent: true,
        name: event.name,
        ref: event.ref,
        eventDate: event.date,
      }));
  }

  const eventsMap = chartsInfo.events.reduce((acc, event) => {
    const key = `${event.ref}Ref`;
    acc[key] = event.date;
    return acc;
  }, {});

  return { adjustedEvents, eventsMap, eventTelemetry };
};

// Búsqueda de fecha posterior más próxima para mostrar eventos en la gráfica
export const findNextDate = (targetDate, availableDates) => {
  const target = new Date(parseDate(targetDate));

  const nextDate = availableDates.reduce((closest, current) => {
    const currentDate = new Date(parseDate(current));
    const closestDate = closest ? new Date(parseDate(closest)) : null;

    if (currentDate >= target && (!closestDate || currentDate < closestDate)) {
      return current; // Actualiza si es la fecha más próxima y válida
    }

    return closest; // Mantiene la fecha más próxima encontrada
  }, null);

  return nextDate || null; // Devuelve null si no hay fecha válida
};

// Actualización de telemetrías para incluir información de eventos
export const updateTelemetryInfo = (
  telemetryInfo,
  adjustedEvents,
  eventTelemetry
) => {
  const hasInReturn = adjustedEvents.some((event) => event.ref === "inReturn");

  const filteredEvents = adjustedEvents.filter((event) => {
    if (hasInReturn && event.ref === "delivered") {
      return false;
    }
    return true;
  });

  const updatedTelemetry = telemetryInfo.map((telemetry) => {
    const matchingEvent = filteredEvents.find(
      (event) => event.closestDate === telemetry.date
    );
    if (matchingEvent) {
      return {
        ...telemetry,
        isEvent: true,
        name: matchingEvent.name,
        ref: matchingEvent.ref,
        eventDate: matchingEvent.date,
      };
    }
    return telemetry;
  });

  const filteredEventTelemetry = eventTelemetry.filter((event) => {
    if (hasInReturn && event.ref === "delivered") {
      return false;
    }
    return true;
  });

  return [...updatedTelemetry, ...filteredEventTelemetry];
};

export function renderHumidityYAxis(showHumidity, isStandalone) {
  return showHumidity && !isStandalone ? (
    <YAxis
      yAxisId="right"
      label={{ value: "H. (%)", position: "top", offset: 25 }}
      orientation="right"
      unit="%"
      domain={[0, 100]}
    />
  ) : (
    <YAxis yAxisId="right" orientation="right" />
  );
}

// Renderizado de líneas de referencia para eventos
export const renderReferenceLines = (chartParameters, showEvents) => {
  if (!showEvents) return null;

  const hasInReturnAndDelivered =
    chartParameters.telemetryInfo.some(
      (data) => data.isEvent && data.ref === "inReturn"
    ) &&
    chartParameters.telemetryInfo.some(
      (data) => data.isEvent && data.ref === "delivered"
    );

  return chartParameters.telemetryInfo
    .filter((data) => {
      if (hasInReturnAndDelivered && data.ref === "delivered") {
        return false;
      }
      return data.isEvent;
    })
    .map((data, index) => (
      <ReferenceLine
        key={`event-${index}`}
        x={data.date}
        stroke="#82ca9d"
        yAxisId="left"
        label={{
          value: data.name,
          position: "insideTopRight",
          fill: "#274227",
          angle: -90,
          fontSize: 14,
          offset: 15,
        }}
      />
    ));
};

// Verificación de datos de humedad disponibles
export const isHumidityDisabled = (telemetryInfo) => {
  return !telemetryInfo.some((data) => data.humidity !== undefined);
};

// Verificación de límites de temperatura definidos
export const isLimitsDisabled = (tempMax, tempMin) => {
  return !tempMax && !tempMin;
};

export const isEventDisabled = (events) => {
  return events.length === 0;
};

// Funciones para obtener el dominio máximo y mínimo de la gráfica
export const getMaxDomainValue = (chartParameters) => {
  const { tempMax, tempMin, maxRecord } = chartParameters;

  if (tempMax === null && tempMin !== null && tempMin > maxRecord) {
    return Math.ceil(tempMin + 2);
  }

  if (tempMax === null || maxRecord > tempMax) {
    return Math.ceil(maxRecord + 2);
  }

  return Math.ceil(tempMax + 2);
};

export const getMinDomainValue = (chartParameters) => {
  const { tempMax, tempMin, minRecord } = chartParameters;

  if (tempMin == null && tempMax !== null && tempMax < minRecord) {
    return Math.floor(tempMax - 2);
  }

  if (tempMin === null || minRecord < tempMin) {
    return Math.floor(minRecord - 2);
  }

  return Math.floor(tempMin - 2);
};

// Función para visualización dinámica de datos en la gráfica
export const handleToggle = (toggleKey, showChart, setToggleStates) => {
  const newState = { ...showChart };

  switch (toggleKey) {
    case "showTemperature":
      if (showChart.showTemperature) {
        newState.showTemperature = false;
        newState.showAlerts = false;
        newState.showLimits = false;
      } else {
        newState.showTemperature = true;
      }
      break;

    case "showAlerts":
      if (!showChart.showTemperature) {
        newState.showTemperature = true;
        newState.showAlerts = true;
      } else {
        newState.showAlerts = !showChart.showAlerts;
      }
      break;

    case "showLimits":
      if (!showChart.showTemperature) {
        newState.showTemperature = true;
        newState.showLimits = true;
      } else {
        newState.showLimits = !showChart.showLimits;
      }
      break;

    case "showEvents":
      newState.showEvents = !showChart.showEvents;
      break;

    case "showHumidity":
      newState.showHumidity = !showChart.showHumidity;
      break;

    default:
      break;
  }

  setToggleStates(newState);
};
