import React, { PureComponent } from "react";
import { parseDate } from "../../utils/dateUtils";

export class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

export const CustomizedDot = (props) => {
  const { cx, cy, value, max, min, data, index, periodMinuteGuard, payload } =
    props;

  const payloadDate = parseDate(payload.date);
  const startDate = parseDate(data[0].date);
  const startDateWithGuard = new Date(
    startDate.getTime() + periodMinuteGuard * 60000
  );
  // const endDate = deliveredRef
  //   ? parseDate(deliveredRef)
  //   : returnedToSenderRef
  //   ? parseDate(returnedToSenderRef)
  //   : null;

  if (!payloadDate) {
    return false;
  }

  if (
    (startDateWithGuard && payloadDate < startDateWithGuard) ||
    // (endDate && payloadDate > endDate) ||
    (payload.isEvent && !payload.temp)
  ) {
    return false;
  }

  let previousValue;
  for (let i = index - 1; i >= 0; i--) {
    const prevData = data[i];
    const prevDate = parseDate(prevData.date);

    if (prevDate >= startDateWithGuard) {
      previousValue = prevData.temp;
      break;
    }
  }

  const isOutOfRange =
    (max !== null && value > max) || (min !== null && value < min);

  const previousIsOutOfRange =
    (max !== null && previousValue > max) ||
    (min !== null && previousValue < min);

  if (isOutOfRange && !previousIsOutOfRange) {
    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        width={20}
        height={20}
        fill="red"
        viewBox="64 64 896 896"
      >
        <path d="M955.7 856l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zM480 416c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v184c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V416zm32 352a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"></path>
      </svg>
    );
  }

  return false;
};

export const CustomizedTooltip = ({ active, payload }) => {
  if (!active || !payload || payload.length === 0) return null;

  const data = payload[0].payload;
  const isTempAndHumidityNaN = isNaN(data.temp) && isNaN(data.humidity);

  return (
    <div className="custom-tooltip">
      <p>{data.date}</p>
      {isTempAndHumidityNaN ? (
        data.isEvent && <p>{data.name}</p>
      ) : (
        <>
          {data.temp && (
            <p className="temp">{`Temperatura: ${data.temp} °C`}</p>
          )}
          {data.humidity && (
            <p className="hum">{`Humedad: ${data.humidity} %`}</p>
          )}
          {data.isEvent && (
            <p>
              {data.name} - {data.eventDate}
            </p>
          )}
        </>
      )}
    </div>
  );
};
