import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "antd";
import { withTranslation } from "react-i18next";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import {
  CustomizedAxisTick,
  CustomizedDot,
  CustomizedTooltip,
} from "../CustomChartComponents/CustomChartComponents";
import {
  processChartInfo,
  updateTelemetryInfo,
  getMinDomainValue,
  getMaxDomainValue,
  handleToggle,
  isHumidityDisabled,
  isLimitsDisabled,
  isEventDisabled,
  renderReferenceLines,
  renderHumidityYAxis,
} from "../../utils/shipmentChartsUtils";

function ShipmentCharts({ trackNum, chartsInfo, isStandalone }) {
  const [chartParameters, setChartParameters] = useState({
    message: "Cargando...",
    status: "loading",
    statusCode: 0,
    track_num: trackNum,
    telemetryInfo: [],
    events: [],
    tempMax: 0,
    tempMin: 0,
    maxRecord: 0,
    minRecord: 0,
    preRegisteredRef: null,
    admittedRef: null,
    deliveredRef: null,
    inReturnRef: null,
    returnedToSenderRef: null,
    periodMinuteGuard: 0,
  });

  const [showChart, setShowChart] = useState({
    showTemperature: true,
    showHumidity: true,
    showLimits: true,
    showEvents: true,
    showAlerts: true,
  });

  useEffect(() => {
    if (chartsInfo.statusCode === 200) {
      const { adjustedEvents, eventsMap, eventTelemetry } =
        processChartInfo(chartsInfo);

      const updatedTelemetry = updateTelemetryInfo(
        chartsInfo.telemetryInfo,
        adjustedEvents,
        eventTelemetry
      );

      setChartParameters((prevState) => ({
        ...prevState,
        ...chartsInfo,
        status: "loaded",
        telemetryInfo: updatedTelemetry,
        events: adjustedEvents,
        ...eventsMap,
        maxRecord: Math.max(
          ...chartsInfo.telemetryInfo.map((data) => data.temp)
        ),
        minRecord: Math.min(
          ...chartsInfo.telemetryInfo.map((data) => data.temp)
        ),
      }));
    } else {
      setChartParameters((prevState) => ({
        ...prevState,
        message: "No se han encontrado datos de telemetría para este envío.",
        status: "loaded",
        statusCode: chartsInfo.statusCode,
      }));
    }
  }, [chartsInfo]);

  return (
    <div className="Charts">
      {chartParameters.status === "loading" ||
      chartParameters.statusCode !== 200 ? (
        <p>{chartParameters.message}</p>
      ) : (
        <Row gutter={16}>
          <Col span={24}>
            <h3>Gráficas de telemetría</h3>
            <div className="btn-section">
              <Button
                onClick={() =>
                  handleToggle("showEvents", showChart, setShowChart)
                }
                className={
                  showChart.showEvents ? "events-btn" : "events-btn inactive"
                }
                disabled={isEventDisabled(chartParameters.events)}
              >
                Trazabilidad Logística
              </Button>
              <Button
                onClick={() =>
                  handleToggle("showTemperature", showChart, setShowChart)
                }
                className={
                  showChart.showTemperature ? "temp-btn" : "temp-btn inactive"
                }
              >
                Temperatura
              </Button>
              <Button
                onClick={() =>
                  handleToggle("showHumidity", showChart, setShowChart)
                }
                className={
                  showChart.showHumidity ? "hum-btn" : "hum-btn inactive"
                }
                disabled={
                  isStandalone ||
                  isHumidityDisabled(chartParameters.telemetryInfo)
                }
              >
                Humedad
              </Button>
              <Button
                onClick={() =>
                  handleToggle("showLimits", showChart, setShowChart)
                }
                className={
                  showChart.showLimits ? "limits-btn" : "limits-btn inactive"
                }
                disabled={
                  isStandalone ||
                  isLimitsDisabled(
                    chartParameters.tempMax,
                    chartParameters.tempMin
                  )
                }
              >
                Rango Temperatura
              </Button>
              <Button
                onClick={() =>
                  handleToggle("showAlerts", showChart, setShowChart)
                }
                className={
                  showChart.showAlerts ? "alerts-btn" : "alerts-btn inactive"
                }
                disabled={isStandalone}
              >
                Alertas Temperatura
              </Button>
            </div>

            <div className="chart">
              <ResponsiveContainer>
                <LineChart
                  data={chartParameters.telemetryInfo}
                  margin={{
                    top: 40,
                    right: 20,
                    bottom: 80,
                    left: 50,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" tick={<CustomizedAxisTick />} />

                  {/* Eje Y para Temperatura */}
                  <YAxis
                    yAxisId="left"
                    label={{
                      value: "T. (ºC)",
                      position: "top",
                      offset: 25,
                    }}
                    unit="°C"
                    domain={[
                      getMinDomainValue(chartParameters),
                      getMaxDomainValue(chartParameters),
                    ]}
                  />

                  {/* Eje Y para Humedad */}
                  {renderHumidityYAxis(showChart.showHumidity, isStandalone)}

                  <Tooltip content={<CustomizedTooltip />} />

                  {/* Límites de Temperatura */}
                  {showChart.showLimits && (
                    <ReferenceLine
                      y={chartParameters.tempMax}
                      label="Max"
                      stroke="#f8c002"
                      yAxisId="left"
                    />
                  )}
                  {showChart.showLimits && (
                    <ReferenceLine
                      y={chartParameters.tempMin}
                      label="Min"
                      stroke="#f8c002"
                      yAxisId="left"
                    />
                  )}

                  {/* Líneas de referencia de Eventos */}
                  {renderReferenceLines(chartParameters, showChart.showEvents)}

                  {/* Línea de Temperatura */}
                  {showChart.showTemperature && (
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="temp"
                      unit="°C"
                      name="Temperatura"
                      stroke="#ef9928"
                      dot={
                        showChart.showAlerts && (
                          <CustomizedDot
                            max={chartParameters.tempMax}
                            min={chartParameters.tempMin}
                            periodMinuteGuard={
                              chartParameters.periodMinuteGuard
                            }
                            data={chartParameters.telemetryInfo}
                          />
                        )
                      }
                    />
                  )}

                  {/* Línea de Humedad */}
                  {showChart.showHumidity && (
                    <Line
                      yAxisId="right"
                      type="monotone"
                      dataKey="humidity"
                      name="Humedad"
                      unit="%"
                      stroke="#3ba3e0"
                      dot={false}
                    />
                  )}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default withTranslation()(ShipmentCharts);
